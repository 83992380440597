import React from 'react';
import { Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Electrolize|Orbitron:400,900');
font-family: 'Electrolize', sans-serif;
body {
  transition: all 600ms ease-in-out;
  background-color: #131313;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}`;

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;

    return (
      <WritingContainer>
        <GlobalStyle />
        <Nav selected="posts" />
        <WritingWrapper>
          <WritingInner>
            <WritingContent id="Writing">{children}</WritingContent>
          </WritingInner>
        </WritingWrapper>
        <Footer />
      </WritingContainer>
    );
  }
}

const WritingContent = styled.div``;

const WritingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-top: 150px;
`;

const WritingWrapper = styled.div`
  padding: 24px;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  min-height: 100%;
  flex: auto;
  @media (max-width: 1000px) {
    padding: 2.4vw;
  }
  @media (max-width: 680px) {
    padding: 4.8vw;
  }
`;

const WritingInner = styled.div`
  max-width: 900px;
  h3 {
    font-size: 48px;
    font-family: Inter;
    font-weight: 900;
    margin-top: 48px;
    @media (max-width: 680px) {
      font-size: 32px;
    }
  }
  .gatsby-highlight {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    overflow: auto;
    padding-bottom: 0;
  }
`;

const Logo = styled(Link)`
  line-height: 75px;
  border-bottom: none !important;
  font-family: Inter, sans-serif;
  font-weight: 900;
  color: #333 !important;
  font-weight: 900;
  text-decoration: none;
  @media (max-width: 680px) {
    line-height: 50px;
    font-size: 14px;
  }
`;

const Links = styled.div`
  float: right;
  a {
    line-height: 75px;
    border-bottom: none !important;
    font-family: Inter, sans-serif;
    color: rgb(141, 141, 141) !important;
    font-weight: 600;
    margin-left: 36px;
    text-decoration: none;
    @media (max-width: 680px) {
      line-height: 50px;
      font-size: 14px;
      margin-left: 24px;
    }
  }
`;

const LinksContainer = styled.div`
  text-align: right;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

export default Layout;
